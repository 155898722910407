
import { defineComponent, onMounted, ref,computed } from 'vue'
import router from '@/router/clean';
import Auths from '@/core/services/Callable/Auths';
import Etcs from '@/core/services/Callable/Etcs';
import { loaderLogo } from "@/core/helpers/config";

export default defineComponent({
    setup() {

        onMounted(async() => {
            const routeParams = router.currentRoute.value.params;

            const payload = {
                'switch_to':routeParams?.switchTo,
                'refresh_token':routeParams?.refreshToken
            }
            
            await Auths.switchApp(payload);
            await Etcs.getRoutes();
            
            const href = router.resolve({ name: "dashboard" })
            window.location.href = href.href;

        })
        const switchTo = computed(() => {
            const routeParams = router.currentRoute.value.params;
            return routeParams?.switchTo;
        });

        return {
            loaderLogo,
            switchTo
        }
    },
})
